import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import Recepti from './Recepti.js'

function App() {
  const APP_ID = '24674ea5';
  const APP_KEY = 'b3cbb50dc8238e87c8735d5ee00aa789';



  const [recepti, setrecepti] = useState([]);
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState('chicken');




  useEffect( ()=>{
    getRecepti();
  },[query]);


 

const updateSearch = e =>{
  setSearch(e.target.value);
  console.log(search);
}

const getQuery = e =>{
  e.preventDefault();
  setQuery(search);
}

const getRecepti = async ()=>{
const response = await fetch(`https://api.edamam.com/search?q=${query}&app_id=${APP_ID}&app_key=${APP_KEY}`);
const data = await response.json();
setrecepti(data.hits);
}
  return (
    <div className="App">
      <h1>Recepti</h1>
      <form onSubmit={getQuery}>
        <input type="text" value={search} onChange={updateSearch}/>
        <button type="submit" >Search</button>
      </form>
      {
        recepti.map((recepat, index)=>(
          <Recepti key={index} naslov={recepat.recipe.label} kalorije={recepat.recipe.calories} slika={recepat.recipe.image}/>
        ))}
    </div>
  );
}

export default App;
