import React from 'react';

function Recepti(props){
    return (
<div>
    <h1>{props.naslov}</h1>
    <img src={props.slika} alt=""/>
    <p>Kalorije {props.kalorije}</p>
</div>
    );
    
}

export default Recepti;